import { Fragment } from "react";
import { Header, Main, Footer } from './index'


const Home = () => {
    return (
        <>
            <Header />
            <Main />
            <Footer />
        </>
    );
};

export default Home;
